import { useMemo } from 'react'
import links from 'links'

import { navMessages } from 'compositions/navigation'

import { useLimitedDropSale } from './useLimitedDropSale'


const MESSAGES_MAP = {
  ComponentLimitedDropDefaultNavigationLink: navMessages.limitedDrop,
  ComponentLimitedDropVaultNavigationLink: navMessages.limitedDropVault,
}

type UseLimitedDropNavigationItemProps = {
  placement: 'footer' | 'header'
}

export const useLimitedDropNavigationItems = ({ placement }: UseLimitedDropNavigationItemProps) => {
  const { data: limitedDropData } = useLimitedDropSale()

  const limitedDropNavigationLinkFragmentIds = limitedDropData?.entryPointsUsageMap?.navigationLink?.Discover

  return useMemo(() => {
    return (limitedDropNavigationLinkFragmentIds || []).map<Navigation.ItemBase>((fragmentId) => {
      const fragment = limitedDropData?.entryPointsMap?.[fragmentId]

      if (!fragment) {
        return null
      }

      const { __typename, withNewLabel } = fragment

      const title = MESSAGES_MAP[__typename]

      if (!title) {
        return null
      }

      return {
        id: `${placement}-discover-limitedDrop`,
        title,
        to: links.currentLimitedDrop,
        label: placement !== 'footer' && withNewLabel && navMessages.labels.new,
      }
    }).filter(Boolean)
  }, [ limitedDropData?.entryPointsMap, limitedDropNavigationLinkFragmentIds, placement ])
}
