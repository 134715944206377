import dayjs, { type DateValue } from 'date'


type GetCountdownDataParams = {
  nowDate: DateValue
  startDate: DateValue
  endDate: DateValue
}

const getCountdownData = ({ nowDate, startDate, endDate }: GetCountdownDataParams): LimitedDropSale.CountdownData => {
  const timeDiff = dayjs(endDate).tz('America/New_York').diff(nowDate)

  const isCountdownStarted = dayjs(startDate).tz('America/New_York').isBefore(nowDate)
  const isCountdownExpired = isCountdownStarted && timeDiff <= 0

  const countdownTimeLeft = isCountdownStarted && !isCountdownExpired ? timeDiff : 0

  return {
    countdownTimeLeft,
    isCountdownStarted,
    isCountdownExpired,
  }
}


export default getCountdownData
