const ANALYTIC_EVENT_STATUS_MAP = {
  LIVE: 'Active',
  SOLD_OUT: 'Sold out',
  FINISHED: 'Ended',
}

const getAnalyticMetadata = (saleData: Pick<LimitedDropSale.SaleData, 'status' | 'products'>): LimitedDropSale.AnalyticMetadata => {
  const analyticMetadata = {
    productIds: [],
    productBrands: [],
    productFullNames: [],
    limitedDropStatus: ANALYTIC_EVENT_STATUS_MAP[saleData.status],
  }

  saleData.products?.forEach((product) => {
    const { id, brandInfo, fullName } = product.productInfo

    analyticMetadata.productIds.push(id)
    analyticMetadata.productBrands.push(brandInfo.name)
    analyticMetadata.productFullNames.push(fullName)

    if (saleData.status === 'LIVE' && product.limitedEditionInfo?.productsRemainingCount === 0) {
      analyticMetadata.limitedDropStatus = 'Partially sold-out'
    }
  })

  return analyticMetadata
}


export default getAnalyticMetadata
