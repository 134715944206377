import dayjs, { type DateValue } from 'date'


const COMPONENT_TYPENAME_TO_FRAGMENT_TYPE_MAP = {
  ComponentLimitedDropModal: 'modal',
  ComponentLimitedDropVaultModal: 'modal',
  ComponentLimitedDropLandingPage: 'landingPage',
  ComponentLimitedDropVaultLandingPage: 'landingPage',
  ComponentLimitedDropDefaultTopBarBanner: 'topBar',
  ComponentLimitedDropVaultTopBarBanner: 'topBar',
  ComponentLimitedDropDefaultHeroBanner: 'heroBanner',
  ComponentLimitedDropVaultHeroBanner: 'heroBanner',
  ComponentLimitedDropDefaultNavigationLink: 'navigationLink',
  ComponentLimitedDropVaultNavigationLink: 'navigationLink',
  ComponentLimitedDropProductsBanner: 'productsBanner',
}

type GetEntryPointsDataParams = {
  nowDate: DateValue
  campaignRules: Pick<
    LimitedDropSale.StrapiCampaignRules,
    | 'bannerFragments'
    | 'heroBannerFragments'
    | 'landingPageFragments'
    | 'modalFragments'
    | 'navigationLinkFragments'
    | 'topBarBannerFragments'
  >
}

const getEntryPointsData = ({ nowDate, campaignRules }: GetEntryPointsDataParams): Pick<LimitedDropSale.Payload, 'entryPointsMap' | 'entryPointsUsageMap'> => {
  const entryPointsMap: LimitedDropSale.EntryPointsMap = {}
  const entryPointsUsageMap: LimitedDropSale.EntryPointsUsageMap = {}

  const allFragmentsList = []
    .concat(
      campaignRules?.bannerFragments,
      campaignRules?.heroBannerFragments,
      campaignRules?.landingPageFragments,
      campaignRules?.modalFragments,
      campaignRules?.navigationLinkFragments,
      campaignRules?.topBarBannerFragments
    )
    .filter((data) => data && data.__typename !== 'Error')

  allFragmentsList.forEach((data) => {
    const { startsFrom, endsAfter } = data

    const fragmentName = COMPONENT_TYPENAME_TO_FRAGMENT_TYPE_MAP[data.__typename]

    const isFragmentVisible = Boolean(fragmentName) ? (
      (startsFrom === null || dayjs(startsFrom).tz('America/New_York').isBefore(nowDate))
      && (endsAfter === null || dayjs(endsAfter).tz('America/New_York').isAfter(nowDate))
    ) : false

    if (!isFragmentVisible) {
      return
    }

    // The ID of fragment is unique only for the own type, but not for the entire dynamic zone
    const uniqueFragmentId = data.__typename + data.id

    // Add fragment data to the map
    entryPointsMap[uniqueFragmentId] = data

    // Add the fragment's id as an indication of the fragment's availability on specific placements
    if (data.placements?.length > 0) {
      data.placements.forEach((placementName: LimitedDropSale.ProductsBannerPlacement) => {
        entryPointsUsageMap[fragmentName] = entryPointsUsageMap[fragmentName] || {}
        entryPointsUsageMap[fragmentName][placementName] = entryPointsUsageMap[fragmentName][placementName] || []
        entryPointsUsageMap[fragmentName][placementName].push(uniqueFragmentId)
      })
    }
    else {
      entryPointsUsageMap[fragmentName] = isFragmentVisible ? uniqueFragmentId : undefined
    }
  })

  return {
    entryPointsMap,
    entryPointsUsageMap,
  }
}


export default getEntryPointsData
